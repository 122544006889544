import React from 'react';
import { COLORS } from '@theme';

const CNET = ({ color = COLORS.darkBlue }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 45 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.089 22.757h1.719v2.01h-2.65a3.943 3.943 0 0 1-1.488-.313c-1.235-.536-1.711-1.853-1.816-3.32V4.295h2.791V8.52h2.65v1.957h-2.65v10.857a1.317 1.317 0 0 0 1.444 1.422Zm-15.583-1.548v-9.235c-.082-5.21 9.272-5.21 9.116.134v4.465a.499.499 0 0 1-.499.49H30.29v4.302c0 2.04 3.602 2.01 3.602 0v-2.061h2.731v1.935c.156 5.306-9.198 5.18-9.116-.03Zm6.333-6.214v-3.021c0-2.01-3.55-2.01-3.602 0v3.021h3.602Zm-11.721-3.274c0-1.883-3.26-1.831-3.312.074v12.971H14.78v-2.01h1.288V10.53h-1.489V8.55h3.885v1.489a5.21 5.21 0 0 1 1.712-1.37 3.312 3.312 0 0 1 2.887-.096c1.235.536 1.712 1.853 1.816 3.326v10.903h1.763v1.986h-4.465l-.06-13.067ZM11.104.424h1.994v32h-1.994v-32ZM.157 21.209v-9.235c-.075-5.21 9.28-5.21 9.124.134v1.6H6.549v-1.734c0-1.98-3.52-1.928-3.601.082v9.28c0 2.039 3.601 2.009 3.601 0v-2.062H9.31v1.935c.112 5.336-9.242 5.21-9.153 0Z"
      fill={color}
    />
  </svg>
);

export default CNET;
